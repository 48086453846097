import api, { UnderwritingQuestionConfig, UnderwritingQuestionType, underwritingQuestionTypeDisplay } from '@api';
import {
    DialogActions, DialogContent, Divider, Typography
} from '@mui/material';
import {
    Button, RoutedDialog, RoutedDialogImplProps, Switch, TextField, renderEnumOptions, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UnderwritingQuestionContext } from '../UnderwritingStepDetailPage';

import styles from './AddEditQuestionDialog.module.scss';
import { CustomFormContent } from './form-components/CustomFormContent';
import { ForEachFormContent, useIsForEachChild } from './form-components/ForEachFormContent';
import { FormEntryFormContent } from './form-components/FormEntryFormContent';
import { InfoFormContent } from './form-components/InfoFormContent';
import { NextActionForm } from './form-components/NextActionForm';
import { SideEffectCard } from './form-components/SideEffectCard';
import { SingleSelectFormContent } from './form-components/SingleSelectFormContent';
import { TableEntryEntryFormContent } from './form-components/TableEntryFormContent';


export type UnderwritingQuestionFormValues = UnderwritingQuestionConfig;

interface UnderwritingQuestionDialogLocationState {
    parentQuestionId: string;
}

export default function AddEditQuestionDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { underwritingCategoryConfigId, underwritingStepConfigId, underwritingQuestionConfigId } = useParams();
    const { questions, setQuestions } = useContext(UnderwritingQuestionContext);
    const configToEdit = questions.find(({ id }) => id === underwritingQuestionConfigId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const location = useLocation();
    const { parentQuestionId } = (
        location.state as UnderwritingQuestionDialogLocationState
    ) || { parentQuestionId: configToEdit?.parentQuestionId };

    const formMethods = useForm<UnderwritingQuestionFormValues>({
        defaultValues: configToEdit ? configToEdit : {
            parentQuestionId
        }
    });
    const { handleSubmit } = formMethods;
    const [ saveLoading, setSaveLoading ] = useState(false);

    const questionType = formMethods.watch('type');

    // cleanup question-specififc fields when question type changes
    useEffect(() => {
        // if questionType changes from form, clean up form fields
        if (questionType !== UnderwritingQuestionType.FORM) {
            formMethods.reset({
                ...formMethods.getValues(),
                fields: undefined
            });
        }

        // if questionType changes from single select, clean up choices
        if (questionType !== UnderwritingQuestionType.SINGLE_SELECT) {
            formMethods.reset({
                ...formMethods.getValues(),
                choices: undefined
            });
        }

        // if questionType changes from table entry, clean up columns
        if (questionType !== UnderwritingQuestionType.TABLE_ENTRY) {
            formMethods.reset({
                ...formMethods.getValues(),
                columns: undefined
            });
        }

        // if questionType changes to single select, cleanup next
        if (questionType === UnderwritingQuestionType.SINGLE_SELECT) {
            formMethods.reset({
                ...formMethods.getValues(),
                next: undefined
            });
        }
    }, [ formMethods, questionType ]);

    const { isForEachChild } = useIsForEachChild(parentQuestionId);

    async function onSubmit(formValues: UnderwritingQuestionFormValues) {
        setSaveLoading(true);

        try {
            if (configToEdit) {
                await api.underwriting.updateUnderwritingQuestionConfig(
                    clientId, underwritingCategoryConfigId!, underwritingStepConfigId!, formValues
                );
                pageMessage.success('Underwriting question configuration updated');
            } else {
                // set parentQuestionId if it exists (otherwise it sets it to undefined)
                formValues.parentQuestionId = parentQuestionId;

                await api.underwriting.createUnderwritingQuestionConfig(
                    clientId,
                    underwritingCategoryConfigId!,
                    underwritingStepConfigId!,
                    formValues
                );
                pageMessage.success('New underwriting question configuration created');
            }

            setQuestions(await api.underwriting.getUnderwritingQuestionConfigs(
                clientId, underwritingCategoryConfigId!, underwritingStepConfigId!
            ));
            navigate(props.closeTo);
        } catch (error) {
            pageMessage.handleApiError('Error adding question', error);
        }

        setSaveLoading(false);
    }

    const sideEffects = useFieldArray<UnderwritingQuestionFormValues>({
        name: 'next.sideEffects',
        control: formMethods.control
    });

    return (
        <RoutedDialog
            {...props}
            title={configToEdit ? 'Edit question' : 'Add question'}
            saveLoading={saveLoading}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <form
                    noValidate
                    id={formId}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.dialogContent}>
                            <Switch<UnderwritingQuestionFormValues>
                                name="showNextQuestion"
                                label="Show next question immediately"
                            />

                            <TextField<UnderwritingQuestionConfig>
                                name="text"
                                label="Question description"
                                required
                            />

                            <TextField<UnderwritingQuestionConfig>
                                name="type"
                                label="Question type"
                                required
                                // disable changing the type if it is a foreach
                                disabled={configToEdit && questionType === UnderwritingQuestionType.FOREACH}
                                select
                            >
                                {renderEnumOptions(getAvailableQuestionTypes(isForEachChild, parentQuestionId))}
                            </TextField>

                            {/* Render the NextActionForm for all question types except single select
                            (the next actions for those are handled in the choices) */}
                            {questionType !== UnderwritingQuestionType.SINGLE_SELECT && (
                                <NextActionForm />
                            )}

                            {questionType === UnderwritingQuestionType.SINGLE_SELECT && (
                                <SingleSelectFormContent />
                            )}

                            {questionType === UnderwritingQuestionType.TABLE_ENTRY && (
                                <TableEntryEntryFormContent />
                            )}

                            {questionType === UnderwritingQuestionType.INFO && (
                                <InfoFormContent />
                            )}

                            {questionType === UnderwritingQuestionType.CUSTOM && (
                                <CustomFormContent />
                            )}

                            {questionType === UnderwritingQuestionType.FOREACH && (
                                <ForEachFormContent />
                            )}

                            {questionType === UnderwritingQuestionType.FORM && (
                                <FormEntryFormContent />
                            )}

                            {!!sideEffects.fields.length && (
                                <>
                                    <Divider />

                                    <Typography variant="h6">
                                        Side Effects
                                    </Typography>

                                    {sideEffects.fields.map((sideEffect, index) => (
                                        <SideEffectCard
                                            key={sideEffect.id}
                                            sideEffects={sideEffects}
                                            index={index}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                {
                    // single select question effects get added in the choices, not globally
                    questionType !== UnderwritingQuestionType.SINGLE_SELECT && (
                        <Button onClick={() => {
                            sideEffects.append({});
                        }}
                        >
                            Add side effect
                        </Button>
                    )
                }

                <Button
                    variant="contained"
                    color="primary"
                    form={formId}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-question-form';

function getAvailableQuestionTypes(isForEachChild: boolean, parentQuestionId?: string) {
    return Object.fromEntries(Object.entries(underwritingQuestionTypeDisplay)
        .filter(([ questionType ]) => {
            // exclude GROUP type because we have a separate button to add a group
            if (questionType === UnderwritingQuestionType.GROUP) {
                return false;
            }

            // exclude FOREACH as an option if parentQuestionId is set (we don't want to allow double-nested questions)
            if (questionType === UnderwritingQuestionType.FOREACH) {
                return !parentQuestionId;
            }

            // exclude TABLE_ENTRY as an option if isForEachChild is true
            // (we don't want to allow a table inside a foreach bc that would require two different iterable entities)
            if (questionType === UnderwritingQuestionType.TABLE_ENTRY) {
                return !isForEachChild;
            }

            // otherwise return true
            return true;
        }));
}
