import { UnderwritingQuestionConfig, losIterableEntityDisplay, underwritingFieldTypeDisplay } from '@api';
import {
    AddCircleOutlined, Edit, RemoveCircleOutline, Save
} from '@mui/icons-material';
import {
    Divider, MenuItem, Paper, Typography
} from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, Switch, TextField, isAtLeast, isAtMost, renderEnumOptions
} from '@tsp-ui/core';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import { UnderwritingQuestionForm } from '@views/loans/underwriting/question-cards/FormQuestionCard';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';
import { LOSDataSourceArray, dataSourceByIterableEntity } from '../data-source-mappings/data-source-mappings';

import { useIsForEachChild } from './ForEachFormContent';
import styles from './FormEntryFormContent.module.scss';


export function FormEntryFormContent() {
    const fields = useFieldArray<UnderwritingQuestionFormValues>({
        name: 'fields'
    });

    const formValues = useFormContext<UnderwritingQuestionFormValues>().getValues();

    return (
        <>
            <Divider />

            <div className={styles.root}>
                <div className={styles.fieldsHeader}>
                    <Typography variant="h6">
                        Form Fields
                    </Typography>

                    <IconButton
                        tooltip="Add form field"
                        onClick={() => fields.append({
                            label: '',
                            isRequired: true
                        })}
                        size="small"
                    >
                        <AddCircleOutlined color="primary" />
                    </IconButton>
                </div>

                {fields.fields.map((field, index) => (
                    <FormFieldCard
                        fields={fields}
                        index={index}
                        key={field.id}
                    />
                ))}

                <Divider />

                <Typography variant="h6">
                    Form Preview
                </Typography>

                <UnderwritingQuestionForm
                    question={formValues}
                    getValue={(() => {}) as any} // passing these as no ops because this is only a preview
                    getOnChange={(() => () => {}) as any}
                />
            </div>
        </>
    );
}

interface FormFieldCardProps {
    fields: UseFieldArrayReturn<UnderwritingQuestionConfig, string, 'id'>;
    index: number;
}

export function FormFieldCard({ fields, index }: FormFieldCardProps) {
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();

    const {
        label, size, helperText, dataSourceId, type, isDisabled, isRequired
    } = formMethods.watch(`fields.${index}`);

    const currentField = formMethods.getValues().fields?.[index];

    const { parentEntity, isForEachChild } = useIsForEachChild();

    const [ isEditing, setIsEditing ] = useState(!currentField?.label);

    const sizeInvalid = (size !== undefined && size !== '' as unknown) && (size > 12 || size < 1);
    const dataSourceInvalid = isForEachChild && !dataSourceId;
    const saveDisabled = sizeInvalid || !label || !type || dataSourceInvalid;

    const availableFields = isForEachChild ? dataSourceByIterableEntity[parentEntity] : LOSDataSourceArray;

    const buttons = (
        <div className={styles.buttons}>
            {isEditing ? (
                <IconButton
                    key="save"
                    tooltip="Save"
                    size="small"
                    onClick={() => setIsEditing(false)}
                    disabled={saveDisabled}
                >
                    <Save
                        color={saveDisabled ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            ) : (
                <IconButton
                    tooltip="Edit"
                    size="small"
                    onClick={() => setIsEditing(true)}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            )}

            <IconButton
                tooltip="Remove"
                size="small"
                onClick={() => fields.remove?.(index)}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize="small"
                />
            </IconButton>
        </div>
    );

    return isEditing ? (
        <Paper
            className={clsx(styles.fieldCard, styles.isEditing)}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerEditing}
                fontWeight={500}
            >
                <TextField<UnderwritingQuestionFormValues>
                    className={styles.fullWidth}
                    name={`fields.${index}.label`}
                    variant="standard"
                    label="Field label"
                    size="small"
                />

                {buttons}
            </Typography>

            <TextField
                name={`fields.${index}.type`}
                variant="standard"
                required
                label="Type"
                hideRequiredIndicator
                select
                size="small"
            >
                {renderEnumOptions(underwritingFieldTypeDisplay)}
            </TextField>

            <TextField<UnderwritingQuestionFormValues>
                className={styles.fullWidth}
                name={`fields.${index}.size`}
                variant="standard"
                label="Field Size"
                size="small"
                type="number"
                required
                hideRequiredIndicator
                rules={{
                    ...isAtLeast(1),
                    ...isAtMost(12)
                }}
                helperText="Size is the span of the field out of 12 horizontal columns"
            />

            <TextField<UnderwritingQuestionFormValues>
                name={`fields.${index}.dataSourceId`}
                label="Linked LOS Field"
                variant="standard"
                size="small"
                helperText={isForEachChild ? `Select the LOS field that this form field will be linked to. Only ${losIterableEntityDisplay[parentEntity]} fields are available.` : 'If this field represents a field in the LOS, select the LOS field here'}
                required={!!isForEachChild} // if it's a child of a foreach question, it must have a data source
                select
            >
                {/* TODO LOSDataSourceArray should be fetched above this component and retrieved here from context*/}
                {availableFields.map(({ premicorrFieldName, losFieldId }) => (
                    <MenuItem
                        value={losFieldId}
                        key={losFieldId}
                    >
                        {premicorrFieldName}
                    </MenuItem>
                ))}
            </TextField>

            <TextField<UnderwritingQuestionFormValues>
                className={styles.fullWidth}
                name={`fields.${index}.helperText`}
                variant="standard"
                label="Helper Text"
                size="small"
            />

            <Switch<UnderwritingQuestionFormValues>
                name={`fields.${index}.isRequired`}
                label="Required"
            />

            <Switch<UnderwritingQuestionFormValues>
                name={`fields.${index}.isDisabled`}
                label="Disabled"
            />
        </Paper>
    ) : (
        <Paper
            className={styles.fieldCard}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerContainer}
                fontWeight={500}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={600}
                >
                    {label}
                </Typography>

                {buttons}
            </Typography>

            <LabelGroup>
                <LabeledValue
                    value={underwritingFieldTypeDisplay[type]}
                    label="Type:"
                />

                {size && (
                    <LabeledValue
                        value={size}
                        label="Size:"
                    />
                )}

                {helperText && (
                    <LabeledValue
                        value={helperText}
                        label="Helper Text:"
                    />
                )}

                {dataSourceId && (
                    <LabeledValue
                        value={availableFields.find(
                            ({ losFieldId }) => losFieldId === dataSourceId
                        )?.premicorrFieldName}
                        label="LOS Field:"
                    />
                )}

                {(isDisabled === true || isDisabled === false) && (
                    <LabeledValue
                        value={renderBoolean(isDisabled)}
                        label="Disabled:"
                    />
                )}

                {(isRequired === true || isRequired === false) && (
                    <LabeledValue
                        value={renderBoolean(isRequired)}
                        label="Required:"
                    />
                )}
            </LabelGroup>
        </Paper>
    );
}
