import api, { UnderwritingQuestionConfig, UnderwritingQuestionType } from '@api';
import { Button, DialogActions, DialogContent } from '@mui/material';
import {
    RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { UnderwritingQuestionContext } from '../UnderwritingStepDetailPage';

import { UnderwritingQuestionFormValues } from './AddEditQuestionDialog';


export function AddEditGroupDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { underwritingCategoryConfigId, underwritingStepConfigId, underwritingQuestionConfigId } = useParams();
    const { questions, setQuestions } = useContext(UnderwritingQuestionContext);
    const configToEdit = questions.find(({ id }) => id === underwritingQuestionConfigId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const formMethods = useForm<UnderwritingQuestionFormValues>({
        defaultValues: configToEdit
    });
    const { handleSubmit } = formMethods;
    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(formValues: UnderwritingQuestionFormValues) {
        setSaveLoading(true);

        try {
            if (configToEdit) {
                await api.underwriting.updateUnderwritingQuestionConfig(
                    clientId, underwritingCategoryConfigId!, underwritingStepConfigId!, formValues
                );
                pageMessage.success('Underwriting question group updated');
            } else {
                formValues.type = UnderwritingQuestionType.GROUP;
                await api.underwriting.createUnderwritingQuestionConfig(
                    clientId,
                    underwritingCategoryConfigId!,
                    underwritingStepConfigId!,
                    formValues
                );
                pageMessage.success('New underwriting question group created');
            }

            setQuestions(await api.underwriting.getUnderwritingQuestionConfigs(
                clientId, underwritingCategoryConfigId!, underwritingStepConfigId!
            ));
            navigate(props.closeTo);
        } catch (error) {
            pageMessage.handleApiError('Error adding question', error);
        }

        setSaveLoading(false);
    }

    return (
        <RoutedDialog
            {...props}
            title={configToEdit ? 'Edit group' : 'Add group'}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <form
                    noValidate
                    id={formId}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>
                        <div>
                            <TextField<UnderwritingQuestionConfig>
                                name="text"
                                label="Group name"
                                required
                            />
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    form={formId}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-group-form';
