import { UnderwritingQuestionConfig, losIterableEntityDisplay } from '@api';
import {
    AddCircleOutlined, Edit, RemoveCircleOutline, Save
} from '@mui/icons-material';
import {
    Divider, MenuItem, Paper, Typography
} from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, TextField, renderEnumOptions
} from '@tsp-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';
import { dataSourceByIterableEntity } from '../data-source-mappings/data-source-mappings';

import styles from './TableEntryFormContent.module.scss';


export function TableEntryEntryFormContent() {
    const columns = useFieldArray<UnderwritingQuestionFormValues>({
        name: 'columns'
    });

    return (
        <>
            <TextField<UnderwritingQuestionFormValues>
                name="entityName"
                label="Linked LOS Entity"
                helperText="If this table corresponds to data in the LOS, select the LOS entity that this table will be linked to"
                select
            >
                {renderEnumOptions(losIterableEntityDisplay)}
            </TextField>

            <Divider />

            <div className={styles.root}>
                <div className={styles.columnsHeader}>
                    <Typography variant="h6">
                        Table Columns
                    </Typography>

                    <IconButton
                        tooltip="Add form field"
                        onClick={() => columns.append({
                            label: '',
                            isRequired: true
                        })}
                        size="small"
                    >
                        <AddCircleOutlined color="primary" />
                    </IconButton>
                </div>

                {columns.fields.map((column, index) => (
                    <TableColumnCard
                        columns={columns}
                        index={index}
                        key={column.id}
                    />
                ))}
            </div>
        </>
    );
}

interface TableColumnCardProps {
    columns: UseFieldArrayReturn<UnderwritingQuestionConfig, string, 'id'>;
    index: number;
}

export function TableColumnCard({ columns, index }: TableColumnCardProps) {
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();

    const { columnName, loanFieldId } = formMethods.watch(`columns.${index}`);
    const entityName = formMethods.watch('entityName');

    const [ isEditing, setIsEditing ] = useState(!columnName);

    const saveDisabled = !columnName;

    const buttons = (
        <div className={styles.buttons}>
            {isEditing ? (
                <IconButton
                    key="save"
                    tooltip="Save"
                    size="small"
                    onClick={() => setIsEditing(false)}
                    disabled={saveDisabled}
                >
                    <Save
                        color={saveDisabled ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            ) : (
                <IconButton
                    tooltip="Edit"
                    size="small"
                    onClick={() => setIsEditing(true)}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            )}

            <IconButton
                tooltip="Remove"
                size="small"
                onClick={() => columns.remove?.(index)}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize="small"
                />
            </IconButton>
        </div>
    );

    return isEditing ? (
        <Paper
            className={clsx(styles.columnCard, styles.isEditing)}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerEditing}
                fontWeight={500}
            >
                <TextField<UnderwritingQuestionFormValues>
                    className={styles.fullWidth}
                    name={`columns.${index}.columnName`}
                    variant="standard"
                    label="Column name"
                    size="small"
                />

                {buttons}
            </Typography>

            {entityName && (
                <TextField<UnderwritingQuestionFormValues>
                    name={`columns.${index}.loanFieldId`}
                    label="Linked LOS Field"
                    variant="standard"
                    size="small"
                    helperText="If this column represents a field in the LOS, select the LOS field here"
                    select
                >
                    {dataSourceByIterableEntity[entityName].map(({ premicorrFieldName, losFieldId }) => (
                        <MenuItem
                            value={losFieldId}
                            key={losFieldId}
                        >
                            {premicorrFieldName}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </Paper>
    ) : (
        <Paper
            className={styles.columnCard}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerContainer}
                fontWeight={500}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={600}
                >
                    {columnName}
                </Typography>

                {buttons}
            </Typography>

            {/* TODO add a 'type' property (number, percentage, currency, etc) to columns and display that here */}

            {loanFieldId && (
                <LabelGroup>
                    <LabeledValue
                        label="Linked LOS Field:"
                        value={dataSourceByIterableEntity[entityName!].find(
                            ({ losFieldId }) => losFieldId === loanFieldId
                        )?.premicorrFieldName}
                    />
                </LabelGroup>
            )}
        </Paper>
    );
}
