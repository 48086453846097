import api, {
    PermissionType, UnderwritingCategory, UnderwritingQuestionConfig,
    UnderwritingStepConfig, underwritingQuestionTypeDisplay
} from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './UnderwritingQuestionConfigCard.module.scss';


interface UnderwritingQuestionConfigCardProps {
    underwritingCategoryConfig: UnderwritingCategory;
    underwritingStepConfig: UnderwritingStepConfig;
    underwritingQuestionConfig: UnderwritingQuestionConfig;
    onUpdate: () => Promise<void>;
}

export function UnderwritingQuestionConfigCard({
    underwritingStepConfig, underwritingCategoryConfig, onUpdate, underwritingQuestionConfig
}: UnderwritingQuestionConfigCardProps) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();
    const [ isDeleting, setIsDeleting ] = useState(false);

    async function onDelete() {
        if (!await confirm('Are you sure you want to delete this underwriting question configuration?')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingQuestionConfig(
                clientId, underwritingCategoryConfig.id, underwritingStepConfig.id, underwritingQuestionConfig.id
            );
            await onUpdate();
            pageMessage.success('Underwriting question configuration deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting question configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <Typography>
                    {underwritingQuestionConfig.text}
                </Typography>

                <LabelGroup>
                    <LabeledValue
                        label="Type:"
                        value={underwritingQuestionTypeDisplay[underwritingQuestionConfig.type]}
                    />

                </LabelGroup>
            </div>

            <span className={styles.icons}>
                <IconButton
                    tooltip={canManage ? 'Edit underwriting question configuration' : 'You do not have permission to edit underwriting question configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`${underwritingQuestionConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete underwriting question configuration' : 'You do not have permission to delete underwriting question configurations'}
                    disabled={!canManage}
                    onClick={onDelete}
                    loading={isDeleting}
                    autoMarginLoader={false}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>
        </Paper>
    );
}
