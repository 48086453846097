import api, {
    LiabilityExclusionType,
    SpecialLiabilityType, liabilityExclusionTypeDisplay, specialLiabilityTypeDisplay
} from '@api';
import { Delete, Edit, HelpOutline } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import {
    CardTable,
    ExpandableHeader,
    FilledSection, IconButton, LabelGroup, LabeledValue, useConfirm, usePageMessage
} from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { UnderWritingStepContext } from '@views/loans/underwriting/UnderwritingStepSection';
import clsx from 'clsx';
import {
    Fragment,
    ReactNode, useCallback, useContext,
    useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom';


import LiabilityCard from './LiabilitiesCard';
import styles from './LiabilitiesSection.module.scss';


export default function LiabilitiesSection() {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const loanId = useContext(LoanDetailContext).loanDetail?.id;
    const pageMessage = usePageMessage();
    const {
        liabilities, setLiabilities, specialLiabilities, setSpecialLiabilities
    } = useContext(UnderWritingStepContext);
    const [ deletingStates, setDeletingStates ] = useState<Record<string, boolean>>({});

    const reloadLiabilities = useCallback(async () => {
        if (!loanId) {
            return;
        }

        try {
            setLiabilities(await api.underwriting.liability.getUnderwritingLiabilities(clientId, loanId, customerId));
        } catch (e) {
            pageMessage.handleApiError('Error updating liabilities', e);
        }
    }, [
        clientId, customerId, loanId, pageMessage, setLiabilities
    ]);

    async function onQuestionAnswer(type: SpecialLiabilityType | SpecialLiabilityType[], answer: string) {
        if (answer === 'Yes') {
            navigate('add-special-liability', { state: { selectedType: type } });
        } else {
            const typesToCheck = Array.isArray(type) ? type : [ type ];
            const liabilitiesToDelete = specialLiabilities.filter(l => typesToCheck.includes(l.specialLiablityType));

            if (liabilitiesToDelete.length && await confirm('Are you sure? This will delete any special liabilitie(s) related to this question')) {
                for (const liability of liabilitiesToDelete) {
                    await deleteSpecialLiability(liability.id);
                }
            }
        }
    }

    async function deleteSpecialLiability(id: string) {
        if (!loanId) {
            return;
        }

        setDeletingStates(prev => ({
            ...prev,
            [id]: true
        }));

        try {
            await api.underwriting.liability.deleteSpecialUnderwritingLiability(
                clientId,
                loanId,
                id,
                customerId
            );
            const updatedLiabilities = await api.underwriting.liability.getSpecialUnderwritingLiabilities(
                clientId,
                loanId,
                customerId
            );
            setSpecialLiabilities(updatedLiabilities);
        } catch (e) {
            pageMessage.handleApiError('Error deleting special liability', e);
        }

        setDeletingStates(prev => ({
            ...prev,
            [id]: false
        }));
    }

    function getActiveAnswer(type: SpecialLiabilityType | SpecialLiabilityType[]) {
        if (Array.isArray(type)) {
            return type.some(t => specialLiabilities.some(l => l.specialLiablityType === t)) ? 'Yes' : 'No';
        }
        return specialLiabilities.some(l => l.specialLiablityType === type) ? 'Yes' : 'No';
    }

    const includedLiabilities = liabilities.filter(l => !l.excluded);
    const excludedLiabilities = liabilities.filter(l => l.excluded);

    const totalMonthlyLiabilities = liabilities.reduce(
        (total, liability) => total + (liability.monthlyPayment || 0),
        0
    );

    const totalSpecialLiabilities = specialLiabilities.reduce(
        (total, liability) => Number(total) + Number(liability.monthlyPayment || 0),
        0
    );

    // Calculate total for liabilities to be paid off
    const totalToPayOff = excludedLiabilities
        .filter(l => l.exclusionType === LiabilityExclusionType.PAY_OFF)
        .reduce((total, liability) => total + (liability.monthlyPayment || 0), 0);

    // Calculate total for omitted liabilities
    const totalOmitted = excludedLiabilities
        .filter(l => l.exclusionType === LiabilityExclusionType.OMIT)
        .reduce((total, liability) => total + (liability.monthlyPayment || 0), 0);

    const totalShortTermDebts = includedLiabilities
        .filter(l => (l.monthsLeft || 0) < 10)
        .reduce((total, liability) => total + (liability.monthlyPayment || 0), 0);

    const formatCurrency = (amount: number) => (amount > 0
        ? `$${amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`
        : '--');

    return (
        <>
            <div className={styles.headerContainer}>
                <ExpandableHeader
                    title="Regular Liabilities"
                    disableExpand={false}
                    defaultExpand={false}
                    secondaryText={`${liabilities.length} liabilities`}
                    className={styles.header}
                    expandedContent={(
                        <div className={styles.liabilities}>
                            <div className={styles.addButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to="add-liability"
                                >
                                    Add Liability
                                </Button>
                            </div>

                            <FilledSection
                                header={(
                                    <>
                                        Included

                                        <Tooltip
                                            title="Excludes mortgages. Review mortgages on the REO screen."
                                            enterDelay={0}
                                        >
                                            <HelpOutline color="primary" />
                                        </Tooltip>
                                    </>
                                )}
                                className={clsx(styles.section, styles.activeLiabilities)}
                                variant="light"
                                noResultsMessage="No included liabilities."
                            >
                                {includedLiabilities.map((liability) => (
                                    <LiabilityCard
                                        key={liability.id}
                                        liability={liability}
                                        onUpdate={reloadLiabilities}
                                        loanId={loanId}
                                    />
                                ))}
                            </FilledSection>

                            <FilledSection
                                header="Excluded"
                                variant="light"
                                className={styles.section}
                                noResultsMessage="No excluded liabilities."
                            >
                                {exclusionTypes.map(exclusionType => {
                                    const liabilitiesByType = excludedLiabilities.filter(
                                        l => l.exclusionType === exclusionType
                                    );

                                    return liabilitiesByType.length > 0 && (
                                        <Fragment key={exclusionType}>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                className={clsx(
                                                    styles.sectionLabel,
                                                    {
                                                        [styles.omitLabel]:
                                                        exclusionType === LiabilityExclusionType.OMIT
                                                    }
                                                )}
                                            >
                                                {liabilityExclusionTypeDisplay[exclusionType]}
                                            </Typography>

                                            {liabilitiesByType.map((liability) => (
                                                <LiabilityCard
                                                    key={liability.id}
                                                    liability={liability}
                                                    onUpdate={reloadLiabilities}
                                                    loanId={loanId}
                                                />
                                            ))}
                                        </Fragment>
                                    );
                                })}
                            </FilledSection>
                        </div>
                    )}
                />

                <ExpandableHeader
                    title="Special Liabilities"
                    disableExpand={false}
                    defaultExpand={false}
                    secondaryText={`${specialLiabilities.length} liabilities`}
                    className={styles.header}
                    expandedContent={(
                        <div className={styles.specialLiabilities}>
                            <Paper
                                variant="outlined"
                                className={styles.questionsWrapper}
                            >
                                <div className={styles.questions}>
                                    {questions.map((question) => (
                                        <div
                                            key={question.id}
                                            className={styles.questionGroup}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={styles.questionText}
                                            >
                                                {question.text}
                                            </Typography>

                                            <div className={styles.buttonGroup}>
                                                {question.options.map((option) => {
                                                    const isActive = getActiveAnswer(question.type) === option;
                                                    return (
                                                        <Button
                                                            key={option}
                                                            variant={isActive ? 'contained' : 'text'}
                                                            disableElevation
                                                            onClick={() => onQuestionAnswer(question.type, option)}
                                                        >
                                                            {option}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Paper>

                            {specialLiabilities.length > 0 && (
                                <CardTable
                                    mediumBackground
                                    className={styles.specialLiabilitiesTable}
                                    headers={tableHeaders}
                                >
                                    {specialLiabilities.map(liability => (
                                        <tr
                                            className={styles.tableRow}
                                            key={liability.id}
                                        >
                                            <Typography
                                                component="td"
                                                variant="body2"
                                            >
                                                {liability.otherDescription
                                                    ? liability.otherDescription
                                                    : specialLiabilityTypeDisplay[liability.specialLiablityType]}
                                            </Typography>

                                            <Typography
                                                component="td"
                                                variant="body2"
                                            >
                                                {liability.monthlyPayment}
                                            </Typography>

                                            <Typography
                                                component="td"
                                                variant="body2"
                                            >
                                                {liability.accountBalance || '--'}
                                            </Typography>

                                            <Typography
                                                component="td"
                                                variant="body2"
                                            >
                                                {liability.monthsLeft || '--'}
                                            </Typography>

                                            <Typography
                                                component="td"
                                                variant="body2"
                                            >
                                                {liability.creditLimit || '--'}
                                            </Typography>

                                            <td className={styles.iconTableCell}>
                                                <IconButton
                                                    component={Link}
                                                    to={`edit-special-liability/${liability.id}`}
                                                    tooltip="Edit"
                                                    color="secondary"
                                                    size="small"
                                                >
                                                    <Edit />
                                                </IconButton>

                                                <IconButton
                                                    tooltip="Delete"
                                                    color="error"
                                                    size="small"
                                                    loading={deletingStates[liability.id] || false}
                                                    onClick={async () => await confirm('Are you sure you want to delete this special liability?')
                                                        && deleteSpecialLiability(liability.id)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </CardTable>
                            )}

                            {getActiveAnswer(questions[2].type) === 'Yes' && (
                                <div className={styles.addSpecialButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to="add-special-liability"
                                    >
                                        Add Special Liability
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                />
            </div>

            <Paper
                variant="outlined"
                className={styles.summary}
            >
                <LabelGroup>
                    <SummaryLabeledValue
                        label="Total monthly liabilities"
                        value={formatCurrency(totalMonthlyLiabilities)}
                        plus
                    />

                    {specialLiabilities.map((liability) => (
                        <SummaryLabeledValue
                            label={liability.otherDescription
                                ? liability.otherDescription
                                : specialLiabilityTypeDisplay[liability.specialLiablityType]}
                            value={formatCurrency(liability.monthlyPayment)}
                            plus
                        />
                    ))}

                    <SummaryLabeledValue
                        label="To be paid off"
                        value={formatCurrency(totalToPayOff)}
                        minus
                    />

                    <SummaryLabeledValue
                        label="Omitted"
                        value={formatCurrency(totalOmitted)}
                        minus
                    />

                    <SummaryLabeledValue
                        label="Installment debts < 10 months"
                        value={formatCurrency(totalShortTermDebts)}
                        minus
                    />

                    <SummaryLabeledValue
                        label="Total liabilities considered in DTI"
                        value={formatCurrency(
                            totalMonthlyLiabilities
                            + totalSpecialLiabilities - totalToPayOff - totalOmitted - totalShortTermDebts
                        )}
                        bold
                    />
                </LabelGroup>

                <Button variant="contained">
                    Done
                </Button>
            </Paper>
        </>
    );
}

const exclusionTypes = [ LiabilityExclusionType.PAY_OFF, LiabilityExclusionType.OMIT ] as const;
const tableHeaders = [
    'Description', 'Monthly Payment', 'Account Balance', 'Months Left', 'Credit Limit', ''
];

interface SummaryLabeledValueProps {
    label: string;
    value: ReactNode;
    className?: string;
    bold?: boolean;
    plus?: boolean;
    minus?: boolean;
}

// TODO move to separate file
export function SummaryLabeledValue({
    label, value, bold, plus, minus, className: classNameProp
}: SummaryLabeledValueProps) {
    const className = clsx(classNameProp, {
        [styles.bold]: bold
    });

    return (
        <LabeledValue
            label={(plus || minus) ? (
                <>
                    {label}&nbsp;

                    {plus ? (
                        <span className={styles.plus}>(+)</span>
                    ) : (
                        <span className={styles.minus}>
                            <span className={styles.minusLetterSpacing}>(-</span>)
                        </span>
                    )}

                    :&nbsp;
                </>
            ) : (
                <>
                    {label}:&nbsp;
                </>
            )}
            value={value}
            variants={{
                value: 'body1',
                label: 'body2'
            }}
            classNames={{
                label: className,
                value: clsx(className, styles.rightAlign)
            }}
        />
    );
}

const questions = [
    {
        id: '0',
        text: 'Is the borrower required to pay child support?',
        type: SpecialLiabilityType.CHILD_SUPPORT,
        options: [ 'Yes', 'No' ]
    },
    {
        id: '1',
        text: 'Is the borrower required to pay alimony?',
        type: SpecialLiabilityType.ALIMONY,
        options: [ 'Yes', 'No' ]
    },
    {
        id: '2',
        text: 'Any other special liabilities?',
        type: [
            SpecialLiabilityType.OTHER,
            SpecialLiabilityType.JOB_RELATED_EXPENSES,
            SpecialLiabilityType.SEPARATE_MAINTENANCE_EXPENSE
        ],
        options: [ 'Yes', 'No' ]
    }
];
