import api, {
    PermissionType,
    UnderwritingCategory, UnderwritingQuestionConfig,
    UnderwritingQuestionType,
    UnderwritingStepConfig,
    losIterableEntityDisplay
} from '@api';
import {
    AddCircleOutlined, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Chip,
    Link as MuiLink, Paper, Typography
} from '@mui/material';
import {
    Button, FilledSection, IconButton, RoutedDialogManager, useAsyncEffect, useConfirm, usePageMessage
} from '@tsp-ui/core';
import {
    useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission, withAuth
} from '@utils';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './UnderwritingStepDetailPage.module.scss';
import { AddEditGroupDialog } from './question-configuration-components/AddEditGroupDialog';
import AddEditQuestionDialog from './question-configuration-components/AddEditQuestionDialog';
import { UnderwritingQuestionConfigCard } from './question-configuration-components/UnderwritingQuestionConfigCard';


export interface UnderwritingQuestionContextValue {
    questions: UnderwritingQuestionConfig[];
    setQuestions: Dispatch<SetStateAction<UnderwritingQuestionConfig[]>>;
}

export const UnderwritingQuestionContext = createContext<UnderwritingQuestionContextValue>({
    questions: [],
    setQuestions: () => {}
});

export default function UnderwritingStepDetailPage() {
    const { underwritingCategoryConfigId, underwritingStepConfigId } = useParams();
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);
    const [ categoryConfigs, setCategoryConfigs ] = useState<UnderwritingCategory[]>([]);
    const [ steps, setSteps ] = useState<UnderwritingStepConfig[]>([]);
    const [ questions, setQuestions ] = useState<UnderwritingQuestionConfig[]>([]);
    const [ isDeleting, setIsDeleting ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        if (!underwritingCategoryConfigId || !underwritingStepConfigId) {
            return;
        }

        setLoading(true);

        const [
            categoryConfigsResult, stepConfigsResult, questionConfigsResult
        ] = await Promise.allSettled([
            api.underwriting.getUnderwritingCategories(clientId),
            api.underwriting.getUnderwritingStepConfigs(clientId, underwritingCategoryConfigId),
            api.underwriting.getUnderwritingQuestionConfigs(
                clientId, underwritingCategoryConfigId, underwritingStepConfigId
            )
        ]);

        handlePromiseSettledResult(categoryConfigsResult, setCategoryConfigs, 'An error occurred while fetching underwriting category configs');
        handlePromiseSettledResult(stepConfigsResult, setSteps, 'An error occurred while fetching underwriting step configs');
        handlePromiseSettledResult(questionConfigsResult, setQuestions, 'An error occurred while fetching underwriting question configs');

        setLoading(false);
    }, [
        clientId, handlePromiseSettledResult, underwritingCategoryConfigId, underwritingStepConfigId
    ]));

    // Only reload questions when needed
    const reloadQuestions = useCallback(async () => {
        if (!underwritingCategoryConfigId || !underwritingStepConfigId) {
            return;
        }

        try {
            const questionConfigs = await api.underwriting.getUnderwritingQuestionConfigs(
                clientId,
                underwritingCategoryConfigId,
                underwritingStepConfigId
            );
            setQuestions(questionConfigs);
        } catch (e) {
            pageMessage.handleApiError('Error updating questions', e);
        }
    }, [
        underwritingCategoryConfigId, underwritingStepConfigId, clientId, pageMessage
    ]);

    const underwritingQuestionContextValue = useMemo(() => ({
        questions,
        setQuestions
    }), [ questions ]);

    const currentCategoryConfig = categoryConfigs.find(c => c.id === underwritingCategoryConfigId);
    const currentStepConfig = steps.find(s => s.id === underwritingStepConfigId);

    const questionGroups = questions.filter(q => q.type === UnderwritingQuestionType.GROUP);
    const forEachQuestions = questions.filter(q => q.type === UnderwritingQuestionType.FOREACH);
    const strayQuestions = questions.filter(q => q.type !== UnderwritingQuestionType.GROUP
        && q.type !== UnderwritingQuestionType.FOREACH
        && !q.parentQuestionId);

    const confirm = useConfirm();

    async function onDeleteGroup(underwritingQuestionGroupId: string) {
        if (!await confirm('Are you sure you want to delete this underwriting group? All subquestions will be deleted.')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.deleteUnderwritingQuestionConfig(
                clientId, underwritingCategoryConfigId!, underwritingStepConfigId!, underwritingQuestionGroupId
            );
            await reloadQuestions();
            pageMessage.success('Underwriting question group deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting question configuration', error);
        }

        setIsDeleting(false);
    }

    return (
        <UnderwritingQuestionContext.Provider value={underwritingQuestionContextValue}>
            <Page
                header="Underwriting Step Details"
                loading={loading}
                breadcrumbs={[
                    'Underwriting Categories',
                    currentCategoryConfig?.name || '',
                    currentStepConfig?.name || ''
                ]}
                breadcrumbPathLengths={[
                    // TODO for some reason this isn't working.
                    // Relative traversing the url is not proportional,
                    // i.e. to=".." takes you 2 levels up the path, "../.." takes you 4 levels up
                    1, 1, 2
                ]}
            >
                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                <span>
                                    Underwriting questions
                                </span>

                                <span>
                                    <Button
                                        component={Link}
                                        to="group"
                                        disabled={!canManage}
                                        className={styles.headerButton}
                                    >
                                        Add group
                                    </Button>

                                    <Button
                                        component={Link}
                                        to="add-question"
                                        disabled={!canManage}
                                        className={styles.headerButton}
                                    >
                                        Add question
                                    </Button>
                                </span>
                            </>
                        )}
                        noResultsMessage="No underwriting question configurations are currently configured."
                    >
                        {strayQuestions?.map((question) => (
                            <UnderwritingQuestionConfigCard
                                key={question.id}
                                underwritingCategoryConfig={currentCategoryConfig!}
                                underwritingStepConfig={currentStepConfig!}
                                underwritingQuestionConfig={question}
                                onUpdate={reloadQuestions}
                            />
                        ))}

                        {questionGroups?.map((group) => {
                            const groupQuestions = questions.filter(q => q.parentQuestionId === group.id);

                            return (
                                <Paper
                                    key={group.id}
                                    elevation={0}
                                    className={styles.groupCard}
                                >
                                    <div className={styles.groupContent}>
                                        <Typography variant="h6">
                                            {group.text}
                                        </Typography>

                                        <span>
                                            <span>
                                                <IconButton
                                                    tooltip={canManage ? 'Add question configuration to this group' : 'You do not have permission to edit underwriting question configurations'}
                                                    disabled={!canManage}
                                                    component={Link}
                                                    to="add-question"
                                                    state={{ parentQuestionId: group.id }}
                                                >
                                                    <AddCircleOutlined color="primary" />
                                                </IconButton>

                                                <IconButton
                                                    tooltip={canManage ? 'Edit underwriting question group' : 'You do not have permission to edit underwriting question groups'}
                                                    disabled={!canManage}
                                                    component={Link}
                                                    to={`group/${group.id}`}
                                                >
                                                    <Edit color="secondary" />
                                                </IconButton>

                                                <IconButton
                                                    tooltip={canManage ? 'Delete underwriting question group' : 'You do not have permission to delete underwriting question groups'}
                                                    disabled={!canManage}
                                                    onClick={() => onDeleteGroup(group.id)}
                                                    loading={isDeleting}
                                                    autoMarginLoader={false}
                                                >
                                                    <RemoveCircleOutline color="error" />
                                                </IconButton>
                                            </span>
                                        </span>
                                    </div>

                                    <FilledSection className={styles.subSection}>
                                        {!!groupQuestions.length && groupQuestions.map((question) => (
                                            <UnderwritingQuestionConfigCard
                                                key={question.id}
                                                underwritingCategoryConfig={currentCategoryConfig!}
                                                underwritingStepConfig={currentStepConfig!}
                                                underwritingQuestionConfig={question}
                                                onUpdate={reloadQuestions}
                                            />
                                        ))}

                                        {!groupQuestions.length && (
                                            <Typography
                                                variant="body2"
                                                className={styles.noContent}
                                                color="textSecondary"
                                            >
                                                No questions have been added to this group.{' '}

                                                {canManage && (
                                                    <>
                                                        <MuiLink
                                                            component={Link}
                                                            to="add-question"
                                                            state={{ parentQuestionId: group.id }}
                                                        >
                                                            Click here
                                                        </MuiLink> to set one up.
                                                    </>
                                                )}
                                            </Typography>
                                        )}
                                    </FilledSection>
                                </Paper>
                            );
                        })}

                        {forEachQuestions?.map((forEachQuestion) => {
                            const subQuestions = questions.filter(q => q.parentQuestionId === forEachQuestion.id);

                            return (
                                <Paper
                                    key={forEachQuestion.id}
                                    elevation={0}
                                    className={styles.groupCard}
                                >
                                    <div className={styles.groupContent}>
                                        <span className={styles.entityChip}>
                                            <Typography variant="h6">
                                                {forEachQuestion.text}
                                            </Typography>

                                            <Chip
                                                size="small"
                                                label={`For each ${losIterableEntityDisplay[forEachQuestion.entityName!].toLowerCase()}`}
                                                className={styles.chip}
                                            />
                                        </span>

                                        <span>
                                            <span>
                                                <IconButton
                                                    tooltip={canManage ? 'Add subquestion to this foreach group' : 'You do not have permission to edit underwriting question configurations'}
                                                    disabled={!canManage}
                                                    component={Link}
                                                    to="add-question"
                                                    state={{ parentQuestionId: forEachQuestion.id }}
                                                >
                                                    <AddCircleOutlined color="primary" />
                                                </IconButton>

                                                <IconButton
                                                    tooltip={canManage ? 'Edit for each configuration' : 'You do not have permission to edit underwriting question configurations'}
                                                    disabled={!canManage}
                                                    component={Link}
                                                    to={`${forEachQuestion.id}`}
                                                >
                                                    <Edit color="secondary" />
                                                </IconButton>

                                                <IconButton
                                                    tooltip={canManage ? 'Delete for each question group' : 'You do not have permission to delete underwriting question groups'}
                                                    disabled={!canManage}
                                                    onClick={() => onDeleteGroup(forEachQuestion.id)}
                                                    loading={isDeleting}
                                                    autoMarginLoader={false}
                                                >
                                                    <RemoveCircleOutline color="error" />
                                                </IconButton>
                                            </span>
                                        </span>
                                    </div>

                                    <FilledSection className={styles.subSection}>
                                        {!!subQuestions.length && subQuestions.map((question) => (
                                            <UnderwritingQuestionConfigCard
                                                key={question.id}
                                                underwritingCategoryConfig={currentCategoryConfig!}
                                                underwritingStepConfig={currentStepConfig!}
                                                underwritingQuestionConfig={question}
                                                onUpdate={reloadQuestions}
                                            />
                                        ))}

                                        {!subQuestions.length && (
                                            <Typography
                                                variant="body2"
                                                className={styles.noContent}
                                                color="textSecondary"
                                            >
                                                No subquestions have been added to this foreach question.{' '}

                                                {canManage && (
                                                    <>
                                                        <MuiLink
                                                            component={Link}
                                                            to="add-question"
                                                            state={{ parentQuestionId: forEachQuestion.id }}
                                                        >
                                                            Click here
                                                        </MuiLink> to set one up.
                                                    </>
                                                )}
                                            </Typography>
                                        )}
                                    </FilledSection>
                                </Paper>
                            );
                        })}
                    </FilledSection>
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </UnderwritingQuestionContext.Provider>
    );
}

const dialogRoutes = {
    'add-question': withAuth(AddEditQuestionDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    group: withAuth(AddEditGroupDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    'group/:underwritingQuestionConfigId': withAuth(AddEditGroupDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    ':underwritingQuestionConfigId': withAuth(AddEditQuestionDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true)
};
