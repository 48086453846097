import { UnderwritingQuestionConfig, losIterableEntityDisplay } from '@api';
import {
    AddCircleOutlined, Edit, RemoveCircleOutline, Save
} from '@mui/icons-material';
import {
    MenuItem, Link as MuiLink, Paper, Typography
} from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, TextField
} from '@tsp-ui/core';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { UnderwritingQuestionContext } from '../../UnderwritingStepDetailPage';
import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';
import { LOSDataSourceArray, dataSourceByIterableEntity } from '../data-source-mappings/data-source-mappings';

import { useIsForEachChild } from './ForEachFormContent';
import styles from './NextActionChoiceCard.module.scss';


interface NextActionChoiceCardProps {
    choices: UseFieldArrayReturn<UnderwritingQuestionConfig, string, 'id'>;
    index: number;
}

export function NextActionChoiceCard({ choices, index }: NextActionChoiceCardProps) {
    const { underwritingQuestionConfigId: currentQuestionId } = useParams();
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();
    const { questions } = useContext(UnderwritingQuestionContext);

    const currentChoice = formMethods.getValues().choices?.[index];

    // this will filter out the current question if we are editing. If we are adding, it won't filter anything out
    const questionsExcludingCurrentQuestion = questions.filter((question) => question.id !== currentQuestionId);

    const {
        text, action, continueTo, confirmationMessage, dataSourceId, sideEffects: sideEffectFormValues
    } = formMethods.watch(`choices.${index}`);

    const [ isEditing, setIsEditing ] = useState(!currentChoice?.text);

    const { parentEntity, isForEachChild } = useIsForEachChild();

    const sideEffects = useFieldArray<UnderwritingQuestionFormValues>({
        name: `choices.${index}.sideEffects`
    });

    const sideEffectsIncomplete = sideEffectFormValues
        ? sideEffectFormValues.some((sideEffect) => !sideEffect.text || !sideEffect.type)
        : false;
    const saveDisabled = sideEffectsIncomplete || !text || !action || (action === 'CONTINUE' && !continueTo) || (isForEachChild && !dataSourceId);
    const buttons = (
        <div className={styles.buttons}>
            {isEditing ? (
                <IconButton
                    key="save"
                    tooltip={saveDisabled ? 'Please finish filling out all required fields' : 'Save'}
                    size="small"
                    onClick={() => setIsEditing(false)}
                    disabled={saveDisabled}
                >
                    <Save
                        color={saveDisabled ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            ) : (
                <IconButton
                    tooltip="Edit"
                    size="small"
                    onClick={() => setIsEditing(true)}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            )}

            <IconButton
                tooltip="Remove"
                size="small"
                onClick={() => choices.remove?.(index)}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize="small"
                />
            </IconButton>
        </div>
    );

    const availableFields = isForEachChild ? dataSourceByIterableEntity[parentEntity] : LOSDataSourceArray;

    return isEditing ? (
        <Paper
            className={clsx(styles.choiceCard, styles.isEditing)}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerEditing}
                fontWeight={500}
            >
                <TextField<UnderwritingQuestionFormValues>
                    className={styles.fullWidth}
                    name={`choices.${index}.text`}
                    variant="standard"
                    label="Choice Text"
                    size="small"
                />

                {buttons}
            </Typography>

            <TextField
                name={`choices.${index}.action`}
                variant="standard"
                required
                label="Action"
                hideRequiredIndicator
                select
                size="small"
            >
                <MenuItem
                    value="CONTINUE"
                    key="CONTINUE"
                >
                    Continue
                </MenuItem>

                <MenuItem
                    value="END"
                    key="END"
                >
                    End
                </MenuItem>
            </TextField>

            {action === 'CONTINUE' && (
                <TextField<UnderwritingQuestionFormValues>
                    name={`choices.${index}.continueTo`}
                    label="Continue To"
                    variant="standard"
                    size="small"
                    select
                >
                    {questionsExcludingCurrentQuestion.map((question) => (
                        <MenuItem
                            value={question.id}
                            key={question.id}
                        >
                            {question.text}
                        </MenuItem>
                    ))}
                </TextField>
            )}

            <TextField<UnderwritingQuestionFormValues>
                name={`choices.${index}.dataSourceId`}
                label="Linked LOS Field"
                variant="standard"
                size="small"
                helperText={isForEachChild ? `Select the LOS field that this form field will be linked to. Only ${losIterableEntityDisplay[parentEntity]} fields are available.` : 'If this field represents a field in the LOS, select the LOS field here'}
                required={!!isForEachChild} // if it's a child of a foreach question, it must have a data source
                select
            >
                {/* TODO LOSDataSourceArray should be fetched above this component and retrieved here from context*/}
                {availableFields.map(({ premicorrFieldName, losFieldId }) => (
                    <MenuItem
                        value={losFieldId}
                        key={losFieldId}
                    >
                        {premicorrFieldName}
                    </MenuItem>
                ))}
            </TextField>

            <TextField<UnderwritingQuestionFormValues>
                className={styles.fullWidth}
                name={`choices.${index}.confirmationMessage`}
                variant="standard"
                label="Confirmation Message"
                size="small"
            />

            <span className={styles.sideEffectsHeader}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={600}
                >
                    Side effects:
                </Typography>

                <IconButton
                    tooltip="Add side effect"
                    size="small"
                    onClick={() => sideEffects.append({
                        text: '',
                        action: '',
                        continueTo: '',
                        dataSourceId: ''
                    })}
                >
                    <AddCircleOutlined
                        color="primary"
                        fontSize="small"
                    />
                </IconButton>
            </span>

            {sideEffects.fields.map((sideEffect, sideEffectIndex) => (
                <Paper
                    key={sideEffect.id}
                    className={styles.editSideEffectCard}
                    variant="outlined"
                >
                    <span className={styles.sideEffectFields}>
                        <TextField<UnderwritingQuestionFormValues>
                            name={`choices.${index}.sideEffects.${sideEffectIndex}.text`}
                            variant="standard"
                            label="Text"
                            size="small"
                            required
                        />

                        <TextField<UnderwritingQuestionFormValues>
                            name={`choices.${index}.sideEffects.${sideEffectIndex}.type`}
                            variant="standard"
                            required
                            label="Type"
                            select
                            size="small"
                        >
                            <MenuItem
                                value="SUSPEND_LOAN"
                                key="SUSPEND_LOAN"
                            >
                                Suspend loan
                            </MenuItem>

                            <MenuItem
                                value="GENERATE_CONDITION"
                                key="GENERATE_CONDITION"
                            >
                                Generate condition
                            </MenuItem>
                        </TextField>

                        <TextField<UnderwritingQuestionFormValues>
                            name={`choices.${index}.sideEffects.${sideEffectIndex}.description`}
                            label="Description"
                            variant="standard"
                            size="small"
                        />
                    </span>

                    <IconButton
                        tooltip="Remove"
                        size="small"
                        onClick={() => sideEffects.remove?.(sideEffectIndex)}
                    >
                        <RemoveCircleOutline
                            color="error"
                            fontSize="small"
                        />
                    </IconButton>
                </Paper>
            ))}

            {!sideEffects.fields.length && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    No side effects have been added to this action.&nbsp;
                    <MuiLink
                        onClick={() => sideEffects.append({
                            text: '',
                            action: '',
                            continueTo: '',
                            dataSourceId: ''
                        })}
                        component="span"
                    >
                        Click here
                    </MuiLink> to add one.
                </Typography>
            )}
        </Paper>
    ) : (
        <Paper
            className={styles.choiceCard}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerContainer}
                fontWeight={500}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={600}
                >
                    {text}
                </Typography>

                {buttons}
            </Typography>

            <LabelGroup>
                <LabeledValue
                    value={action === 'CONTINUE' ? 'Continue' : 'End'}
                    label="Action:"
                />

                {action === 'CONTINUE' && (
                    <LabeledValue
                        value={questions.find((question) => question.id === continueTo)?.text}
                        label="Continue to:"
                    />
                )}

                {confirmationMessage && (
                    <LabeledValue
                        value={confirmationMessage}
                        label="Confirmation message:"
                    />
                )}

                {dataSourceId && (
                    <LabeledValue
                        value={availableFields.find(
                            ({ losFieldId }) => losFieldId === dataSourceId
                        )?.premicorrFieldName}
                        label="LOS Field:"
                    />
                )}

            </LabelGroup>

            {sideEffectFormValues && sideEffectFormValues.length > 0 && (
                <>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        fontWeight={600}
                    >
                        Side effects:
                    </Typography>

                    <span />

                    {sideEffectFormValues.map((sideEffect) => (
                        <Paper
                            variant="outlined"
                            key={sideEffect.id}
                            className={styles.displaySideEffectCard}
                        >
                            <LabelGroup>
                                <LabeledValue
                                    value={sideEffect.text}
                                    label="Text:"
                                />

                                <LabeledValue
                                    value={sideEffect.type === 'SUSPEND_LOAN' ? 'Suspend loan' : 'Generate condition'}
                                    label="Type:"
                                />

                                {sideEffect.description && (
                                    <LabeledValue
                                        value={sideEffect.description}
                                        label="Description:"
                                    />
                                )}
                            </LabelGroup>
                        </Paper>
                    ))}
                </>
            )}
        </Paper>
    );
}
